:root {
  --foreground-rgb: #fff;
  // back ground color
  --app-line-bg-rgb: #f2f2f2;
  --app-input-bg-rgb: #fff;
  --app-share-bg-rgb: #fff;
  // title color
  --app-text-color: rgba(12, 12, 12, 1);
  --app-text-60-color: rgba(12, 12, 12, 0.6);
  --app-text-30-color: rgba(12, 12, 12, 0.3);
  --app-primary-color: #1E85FF;

  --other-text-color: #fff;

  // link
  --app-link-color: #1E85FF;

  .ant-pro-list-row-card {
    margin-block: 0 !important;
    padding-inline: 0 !important;
  }
  .ant-pro-checkcard-content {
    padding-inline: 0 !important;
    padding-block: 0 !important;
  }

  .linearSelect1 {
    background: linear-gradient(180deg, rgba(114, 92, 255, 1), rgba(114, 92, 255, 0.5));
    border-radius: 12px;
    border: 0px;
  }
}

