
/* @font-face {
  font-family: 'PlayfairDisplay-Bold-4';
  src: url('https://res-front.pumpsoul.com/font/PlayfairDisplay-Bold-4.ttf') format('truetype');
  font-weight: regular;
  font-style: normal;
  font-display: swap;
} */

:root {
  --max-width: 1100px;
  --border-radius: 12px;
  --font-mono: 'Montserrat', ui-monospace, Menlo, Monaco, 'Cascadia Mono', 'Segoe UI Mono',
    'Roboto Mono', 'Oxygen Mono', 'Ubuntu Monospace', 'Source Code Pro', 'Fira Mono',
    'Droid Sans Mono', 'Courier New', monospace;
  font-family: var(--font-inter), sans-serif;
  font-feature-settings: 'liga' 1, 'calt' 1;
  /* 修复 Chrome 字体连写 */
  --primary-glow: conic-gradient(from 180deg at 50% 50%, #16abff33 0deg, #0885ff33 55deg, #54d6ff33 120deg, #0071ff33 160deg, transparent 360deg);
  --secondary-glow: radial-gradient(#ffffff, rgba(255, 255, 255, 0));
  --tile-start-rgb: 239, 245, 249;
  --tile-end-rgb: 228, 232, 233;
  --tile-border: conic-gradient(#00000080, #00000040, #00000030, #00000020, #00000010, #00000010, #00000080);
  --callout-rgb: 238, 240, 241;
  --callout-border-rgb: 172, 175, 176;
  --card-rgb: 180, 185, 188;
  --card-border-rgb: 131, 134, 135;
  --pietra-yellow: #f9e070;
  --app-link-hover: #ff401a;
  --pietra-pink: #fbf2f0;
  --off-white: #fafafa;
  --pietra-global-header-heigth: 64px;
  --pietra-global-mobile-header-heigth: 64px;
  --search-input-height: 40px;
}
:root .ant-pagination .ant-pagination-item-active {
  border-color: #1E85FF;
}
:root .ant-pagination .ant-pagination-item-active a {
  color: #1E85FF;
}
:root .ant-pagination .ant-pagination-jump-next .ant-pagination-item-container .ant-pagination-item-link-icon {
  color: #1E85FF;
}
:root .ant-pagination .ant-pagination-jump-prev .ant-pagination-item-container .ant-pagination-item-link-icon {
  color: #1E85FF;
}
:root .next-antd-timeline .next-antd-timeline-item-content {
  inset-block-start: unset;
}
:root .iconDisable {
  color: #d9d9d9;
  cursor: not-allowed;
}
:root .iconDisable :hover {
  color: null;
}
:root .iconAble {
  color: #0c0c0c;
  cursor: pointer;
}
:root .iconAble :hover {
  color: var(--app-primary-color);
}
:root .link {
  color: #1E85FF;
}
:root .link:hover {
  color: var(--app-primary-color);
}
:root ul {
  list-style: none;
}
:root .next-antd-modal .next-antd-modal-content {
  padding: 0 !important;
}
:root .commonModalContainer {
  max-width: 450px;
}
@supports (font-variation-settings: normal) {
  :root {
    font-family: var(--font-inter), sans-serif;
  }
}
@media (prefers-color-scheme: dark) {
  :root {
    --foreground-rgb: 255, 255, 255;
    --primary-glow: radial-gradient(rgba(1, 65, 255, 0.4), rgba(1, 65, 255, 0));
    --secondary-glow: linear-gradient(to bottom right, rgba(1, 65, 255, 0), rgba(1, 65, 255, 0), rgba(1, 65, 255, 0.3));
    --tile-start-rgb: 2, 13, 46;
    --tile-end-rgb: 2, 5, 19;
    --tile-border: conic-gradient(#ffffff80, #ffffff40, #ffffff30, #ffffff20, #ffffff10, #ffffff10, #ffffff80);
    --callout-rgb: 20, 20, 20;
    --callout-border-rgb: 108, 108, 108;
    --card-rgb: 100, 100, 100;
    --card-border-rgb: 200, 200, 200;
  }
}
* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
}
html,
body {
  max-width: 100vw;
  overflow-x: hidden;
  font-family: 'Montserrat', sans-serif;
  font-size-adjust: 0.5;
}
body {
  padding: 0;
  margin: 0 auto;
  color: #000;
  background: #fff;
}
a {
  color: inherit;
  text-decoration: none;
}
@media (prefers-color-scheme: dark) {
  html {
    color-scheme: dark;
  }
}
.utility-big {
  color: #000;
  font-size: 16px;
  line-height: 18px;
}
.utility-small {
  color: #141414;
  font-size: 12px;
  line-height: 18px;
  -webkit-appearance: none;
}
@media (min-width: 320px) {
  /* Used for large headlines and branded moments. */
  .xl-headline,
  h1,
  .h1 {
    color: #0c0c0c;
    font-size: 42px;
    line-height: 100%;
    font-weight: normal;
  }
  /* Used for medium headlines and branded moments. */
  h2,
  .h2 {
    color: #0c0c0c;
    font-size: 34px;
    line-height: 100%;
    font-weight: normal;
  }
  /* Used for introductory text, such as a blog post or editorial page. */
  h3,
  .h3 {
    color: #0c0c0c;
    font-size: 22px;
    line-height: 100%;
    font-weight: normal;
  }
  /* Our main body text and is used for long blocks of copy such as blog posts and descriptions,
     as well as product descriptions within a PDP. */
  .body-text {
    color: #0c0c0c;
    font-family: Montserrat;
    font-size: 17px;
    line-height: 22px;
  }
  .body-title {
    color: #0c0c0c;
    font-size: 17px;
    line-height: 22px;
  }
  /* This text is our main body text and is used for long blocks of copy such as blog posts and
     descriptions, as well as product descriptions within a PDP. */
  body,
  html,
  .body-text-small {
    color: #141414;
    font-size: 15px !important;
    line-height: 20px !important;
    font-feature-settings: 'calt' off !important;
  }
}
@media (min-width: 768px) {
  /* Used for the headline on the homepage. */
  .xl-headline {
    font-size: 75px;
    line-height: 100%;
    color: #0c0c0c;
  }
  /* Used for large headlines and branded moments. */
  h1,
  .h1 {
    color: #141414;
    font-size: 60px;
    line-height: 100%;
  }
  /* Used for medium headlines and branded moments. */
  h2,
  .h2 {
    color: #0c0c0c;
    font-size: 42px;
    line-height: 100%;
  }
  /* Used for introductory text, such as a blog post or editorial page. */
  h3,
  .h3 {
    color: #0c0c0c;
    font-size: 24px;
    line-height: 100%;
  }
  /* This text is used with short blocks of copy or when larger body copy is needed. */
  .body-text {
    color: #141414;
    font-size: 15px;
    line-height: 20px;
  }
  /* This text is our main body text and is used for long blocks of copy such as blog posts
     and descriptions, as well as product descriptions within a PDP. */
  body,
  html,
  .body-text-small {
    color: #141414;
    font-size: 16px !important;
    line-height: 18px;
    font-feature-settings: 'calt' off !important;
  }
}
@media (min-width: 576px) {
  .commonModalContainer {
    max-width: calc(100% - 30px);
  }
}


/* Make clicks pass-through */
#nprogress {
  pointer-events: none;
}

#nprogress .bar {
  background: #29d;

  position: fixed;
  z-index: 1031;
  top: 0;
  left: 0;

  width: 100%;
  height: 2px;
}

/* Fancy blur effect */
#nprogress .peg {
  display: block;
  position: absolute;
  right: 0px;
  width: 100px;
  height: 100%;
  box-shadow: 0 0 10px #29d, 0 0 5px #29d;
  opacity: 1.0;
  transform: rotate(3deg) translate(0px, -4px);
}

/* Remove these to get rid of the spinner */
#nprogress .spinner {
  display: block;
  position: fixed;
  z-index: 1031;
  top: 15px;
  right: 15px;
}

#nprogress .spinner-icon {
  width: 18px;
  height: 18px;
  box-sizing: border-box;

  border: solid 2px transparent;
  border-top-color: #29d;
  border-left-color: #29d;
  border-radius: 50%;
  animation: nprogress-spinner 400ms linear infinite;
}

.nprogress-custom-parent {
  overflow: hidden;
  position: relative;
}

.nprogress-custom-parent #nprogress .spinner,
.nprogress-custom-parent #nprogress .bar {
  position: absolute;
}
@keyframes nprogress-spinner {
  0%   { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}


:root {
  --foreground-rgb: #fff;
  --app-line-bg-rgb: #f2f2f2;
  --app-input-bg-rgb: #fff;
  --app-share-bg-rgb: #fff;
  --app-text-color: #0c0c0c;
  --app-text-60-color: rgba(12, 12, 12, 0.6);
  --app-text-30-color: rgba(12, 12, 12, 0.3);
  --app-primary-color: #1E85FF;
  --other-text-color: #fff;
  --app-link-color: #1E85FF;
}
:root .ant-pro-list-row-card {
  margin-block: 0 !important;
  padding-inline: 0 !important;
}
:root .ant-pro-checkcard-content {
  padding-inline: 0 !important;
  padding-block: 0 !important;
}
:root .linearSelect1 {
  background: linear-gradient(180deg, #725cff, rgba(114, 92, 255, 0.5));
  border-radius: 12px;
  border: 0px;
}

/* cyrillic-ext */
@font-face {
  font-family: '__Montserrat_0d75de';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://res-front.pumpsoul.com/ika-test/_next/static/media/3f69592b2fe603c7-s.woff2) format('woff2');
  unicode-range: U+0460-052F, U+1C80-1C8A, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}
/* cyrillic */
@font-face {
  font-family: '__Montserrat_0d75de';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://res-front.pumpsoul.com/ika-test/_next/static/media/6325a8417175c41d-s.woff2) format('woff2');
  unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* vietnamese */
@font-face {
  font-family: '__Montserrat_0d75de';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://res-front.pumpsoul.com/ika-test/_next/static/media/021bc4481ed92ece-s.woff2) format('woff2');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
  font-family: '__Montserrat_0d75de';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://res-front.pumpsoul.com/ika-test/_next/static/media/99b7f73d5af7c3e2-s.woff2) format('woff2');
  unicode-range: U+0100-02BA, U+02BD-02C5, U+02C7-02CC, U+02CE-02D7, U+02DD-02FF, U+0304, U+0308, U+0329, U+1D00-1DBF, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: '__Montserrat_0d75de';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://res-front.pumpsoul.com/ika-test/_next/static/media/4f05ba3a6752a328-s.p.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* cyrillic-ext */
@font-face {
  font-family: '__Montserrat_0d75de';
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url(https://res-front.pumpsoul.com/ika-test/_next/static/media/3f69592b2fe603c7-s.woff2) format('woff2');
  unicode-range: U+0460-052F, U+1C80-1C8A, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}
/* cyrillic */
@font-face {
  font-family: '__Montserrat_0d75de';
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url(https://res-front.pumpsoul.com/ika-test/_next/static/media/6325a8417175c41d-s.woff2) format('woff2');
  unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* vietnamese */
@font-face {
  font-family: '__Montserrat_0d75de';
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url(https://res-front.pumpsoul.com/ika-test/_next/static/media/021bc4481ed92ece-s.woff2) format('woff2');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
  font-family: '__Montserrat_0d75de';
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url(https://res-front.pumpsoul.com/ika-test/_next/static/media/99b7f73d5af7c3e2-s.woff2) format('woff2');
  unicode-range: U+0100-02BA, U+02BD-02C5, U+02C7-02CC, U+02CE-02D7, U+02DD-02FF, U+0304, U+0308, U+0329, U+1D00-1DBF, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: '__Montserrat_0d75de';
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url(https://res-front.pumpsoul.com/ika-test/_next/static/media/4f05ba3a6752a328-s.p.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* cyrillic-ext */
@font-face {
  font-family: '__Montserrat_0d75de';
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: url(https://res-front.pumpsoul.com/ika-test/_next/static/media/3f69592b2fe603c7-s.woff2) format('woff2');
  unicode-range: U+0460-052F, U+1C80-1C8A, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}
/* cyrillic */
@font-face {
  font-family: '__Montserrat_0d75de';
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: url(https://res-front.pumpsoul.com/ika-test/_next/static/media/6325a8417175c41d-s.woff2) format('woff2');
  unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* vietnamese */
@font-face {
  font-family: '__Montserrat_0d75de';
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: url(https://res-front.pumpsoul.com/ika-test/_next/static/media/021bc4481ed92ece-s.woff2) format('woff2');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
  font-family: '__Montserrat_0d75de';
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: url(https://res-front.pumpsoul.com/ika-test/_next/static/media/99b7f73d5af7c3e2-s.woff2) format('woff2');
  unicode-range: U+0100-02BA, U+02BD-02C5, U+02C7-02CC, U+02CE-02D7, U+02DD-02FF, U+0304, U+0308, U+0329, U+1D00-1DBF, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: '__Montserrat_0d75de';
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: url(https://res-front.pumpsoul.com/ika-test/_next/static/media/4f05ba3a6752a328-s.p.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* cyrillic-ext */
@font-face {
  font-family: '__Montserrat_0d75de';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(https://res-front.pumpsoul.com/ika-test/_next/static/media/3f69592b2fe603c7-s.woff2) format('woff2');
  unicode-range: U+0460-052F, U+1C80-1C8A, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}
/* cyrillic */
@font-face {
  font-family: '__Montserrat_0d75de';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(https://res-front.pumpsoul.com/ika-test/_next/static/media/6325a8417175c41d-s.woff2) format('woff2');
  unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* vietnamese */
@font-face {
  font-family: '__Montserrat_0d75de';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(https://res-front.pumpsoul.com/ika-test/_next/static/media/021bc4481ed92ece-s.woff2) format('woff2');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
  font-family: '__Montserrat_0d75de';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(https://res-front.pumpsoul.com/ika-test/_next/static/media/99b7f73d5af7c3e2-s.woff2) format('woff2');
  unicode-range: U+0100-02BA, U+02BD-02C5, U+02C7-02CC, U+02CE-02D7, U+02DD-02FF, U+0304, U+0308, U+0329, U+1D00-1DBF, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: '__Montserrat_0d75de';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(https://res-front.pumpsoul.com/ika-test/_next/static/media/4f05ba3a6752a328-s.p.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}@font-face {font-family: '__Montserrat_Fallback_0d75de';src: local("Arial");ascent-override: 85.79%;descent-override: 22.25%;line-gap-override: 0.00%;size-adjust: 112.83%
}.__className_0d75de {font-family: '__Montserrat_0d75de', '__Montserrat_Fallback_0d75de';font-style: normal
}

